import { PayloadAction } from '@reduxjs/toolkit';
import { createAppSlice } from 'src/store/createAppSlice.ts';
import { ItemShippingType, LocationType, PickupAvailability } from 'src/types/shipping.ts';
import { SchedulingType } from 'src/constants/item.constants';
import { getMainItemByValueCategory } from 'src/helpers/common.ts';
import { Item } from 'src/types/item.ts';
import {
    GenerateShippingParams,
    SchedulePickupParams,
    worthyService,
} from 'src/services/api/worthy.service.ts';
import { formattedAdressToObject } from 'src/helpers/scheduling/common';

interface Address {
    street: string;
    city: string;
    state: string;
    zipcode: string;
    apt: string;
    privateResidence: boolean;
    formatted: string;
}
export interface SchedulingState {
    open: boolean;
    stepsLength: number;
    currentStep: number;
    items: Item[];
    mainItemPublicId: string;
    selectedItems: string[];
    shippingMethod: SchedulingType.PICKUP | SchedulingType.DROPOFF;
    address: Address;
    phoneNumber: string;
    pickupAvailability: PickupAvailability[];
    locations: LocationType[];
    dropOffLocation: string;
    userLocalTime: string;
    date: string;
    time: string;
    timeRange: string;
    loading: boolean;
    error: string | null;
    reset: boolean;
}

const initialState: SchedulingState = {
    open: false,
    stepsLength: 0,
    currentStep: 0,
    items: [],
    mainItemPublicId: '',
    shippingMethod: SchedulingType.PICKUP,
    selectedItems: [],
    address: {
        street: '',
        city: '',
        state: '',
        zipcode: '',
        apt: '',
        privateResidence: false,
        formatted: '',
    },
    phoneNumber: '',
    pickupAvailability: [],
    locations: [],
    userLocalTime: '',
    date: '',
    time: '',
    timeRange: '',
    dropOffLocation: '',
    loading: false,
    error: null,
    reset: false,
};

export const schedulingSlice = createAppSlice({
    name: 'scheduling',
    initialState,
    reducers: (create) => ({
        setOpenScheduling: create.reducer((state, action: PayloadAction<boolean>) => {
            state.open = action.payload;
        }),
        setStepsLength: create.reducer((state, action: PayloadAction<number>) => {
            state.stepsLength = action.payload;
        }),
        setScheduledItems: create.reducer((state, action: PayloadAction<string[]>) => {
            state.selectedItems = action.payload;
        }),
        setReset: create.reducer((state, action: PayloadAction<boolean>) => {
            state.reset = action.payload;
        }),
        setSchedulingMainItem: create.reducer((state, action: PayloadAction<Item[]>) => {
            const items = action.payload;
            state.items = items;
            if (!items.length) {
                state.mainItemPublicId = '';
                return;
            }
            state.selectedItems = items.map((item) => item.publicId);
            const mainItem = getMainItemByValueCategory(action.payload);
            state.mainItemPublicId = mainItem.publicId;
            // todo what it is doing
            if(mainItem?.schedulingDetails?.type){
                state.shippingMethod = mainItem.schedulingDetails.type;
            }
            if (mainItem?.schedulingDetails?.address) {
                state.address.formatted = mainItem.schedulingDetails.address;
                const addressObj = formattedAdressToObject(mainItem.schedulingDetails.address);
                state.address.street = addressObj.street;
                state.address.city = addressObj.city;
                state.address.state = addressObj.state;
                state.address.zipcode = addressObj.zipcode;
                state.address.apt = addressObj.apt;
            }
        }),
        setShippingMethod: create.reducer(
            (state, action: PayloadAction<SchedulingType.DROPOFF | SchedulingType.PICKUP>) => {
                state.shippingMethod = action.payload;
            },
        ),
        setFormattedAddress: create.reducer((state, action: PayloadAction<string>) => {
            state.address.formatted = action.payload;
        }),
        setSchedulingStreet: create.reducer((state, action: PayloadAction<string>) => {
            state.address.street = action.payload;
        }),
        setSchedulingApt: create.reducer((state, action: PayloadAction<string>) => {
            state.address.apt = action.payload;
        }),
        setSchedulingPrivateResidence: create.reducer((state, action: PayloadAction<boolean>) => {
            state.address.privateResidence = action.payload;
        }),
        setSchedulingPhoneNumber: create.reducer((state, action: PayloadAction<string>) => {
            state.phoneNumber = action.payload;
        }),
        setPickupAvailability: create.reducer(
            (state, action: PayloadAction<PickupAvailability[]>) => {
                state.pickupAvailability = action.payload;
            },
        ),
        setLocations: create.reducer((state, action: PayloadAction<LocationType[]>) => {
            state.locations = action.payload;
        }),
        setUserLocalTime: create.reducer((state, action: PayloadAction<string>) => {
            state.userLocalTime = action.payload;
        }),
        setDate: create.reducer((state, action: PayloadAction<string>) => {
            state.date = action.payload;
        }),
        setTime: create.reducer((state, action: PayloadAction<string>) => {
            state.time = action.payload;
        }),
        setTimeRange: create.reducer((state, action: PayloadAction<string>) => {
            state.timeRange = action.payload;
        }),
        setDropOffLocation: create.reducer((state, action: PayloadAction<string>) => {
            state.dropOffLocation = action.payload;
        }),
        nextStep: create.reducer((state) => {
            const prevStep = state.currentStep;
            state.currentStep = prevStep + 1;
            if (state.currentStep === state.stepsLength) {
                state.open = false;
            }
        }),
        prevStep: create.reducer((state) => {
            const prevStep = state.currentStep;
            state.currentStep = Math.max(prevStep - 1, 0);
        }),
        setFlowToStart: create.reducer((state) => {
            state.currentStep = 0;
        }),
        closeScheduling: create.reducer((state) => {
            Object.assign(state, initialState);
        }),
        setError: create.reducer((state, action: PayloadAction<string>) => {
            state.error = action.payload;
        }),
        setLoading: create.reducer((state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        }),
        createShipping: create.asyncThunk<
            ItemShippingType['data'],
            GenerateShippingParams
        >(async ({ itemPublicIds, user, addressObj }) => {
                const response = await worthyService.generateItemShippingByPublicID({ itemPublicIds, user, addressObj });
                if (!response.item || !response.user) {
                    throw new Error('generateItemShippingByPublicID: Invalid item or user');
                }

                return { item: response.item, user: response.user };
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                fulfilled: (state, action: PayloadAction<ItemShippingType['data']>) => {
                    state.loading = false;
                    const shipment = action.payload.item.shipment;
                    const user = action.payload.user;
                    if (!shipment) {
                        throw new Error('Shipment data is missing');
                    }
                    state.pickupAvailability = shipment.pickup_availability;
                    state.locations = shipment.locations;
                    state.userLocalTime = user.local_time;
                },
                rejected: (state) => {
                    state.loading = false;
                },
            }),
        schedulePickup: create.asyncThunk<
            void,
            SchedulePickupParams
        >(async ({ itemPublicId, readyTimestamp, companyCloseTime, dropOffLocations }) => {
                await worthyService
                    .schedulePickup({
                        itemPublicId,
                        readyTimestamp,
                        companyCloseTime,
                        dropOffLocations,
                    });
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                fulfilled: (state) => {
                    state.loading = false;
                },
                rejected: (state) => {
                    state.loading = false;
                    throw new Error('Error scheduling pickup');
                },
            }),
        resetShippingState: create.reducer((state) => {
            Object.assign(state, initialState);
        })
    }),

    selectors: {
        isSchedulingOpen: (state) => state.open,
        getSchedulingItems: (state) => state.items,
        getSchedulingCurrentStep: (state) => state.currentStep,
        getSelectedShippingMethod: (state) => state.shippingMethod,
        getFormattedAddress: (state) => state.address.formatted,
        getStreet: (state) => state.address.street,
        getCity: (state) => state.address.city,
        getState: (state) => state.address.state,
        getZipcode: (state) => state.address.zipcode,
        getApt: (state) => state.address.apt,
        getPrivateResidence: (state) => state.address.privateResidence,
        getPhoneNumber: (state) => state.phoneNumber,
        getPickupAvailability: (state) => state.pickupAvailability,
        getLocations: (state) => state.locations,
        getUserLocalTime: (state) => state.userLocalTime,
        getScheduledDate: (state) => state.date,
        getScheduledTime: (state) => state.time,
        getMainItemPublicId: (state) => state.mainItemPublicId,
        getTimeRange: (state) => state.timeRange,
        getDropOffLocation: (state) => state.dropOffLocation,
        getError: (state) => state.error,
        getLoading: (state) => state.loading,
        getSelectedItems: (state) => state.selectedItems,
        getReset: (state) => state.reset,
    },
});

export const {
    getReset,
    getError,
    getMainItemPublicId,
    getLocations,
    isSchedulingOpen,
    getPickupAvailability,
    getUserLocalTime,
    getCity,
    getState,
    getZipcode,
    getSelectedShippingMethod,
    getPrivateResidence,
    getPhoneNumber,
    getStreet,
    getApt,
    getScheduledTime,
    getScheduledDate,
    getSchedulingItems,
    getSchedulingCurrentStep,
    getFormattedAddress,
    getTimeRange,
    getDropOffLocation,
    getLoading,
    getSelectedItems
} = schedulingSlice.selectors;
export const {
    setReset,
    resetShippingState,
    setScheduledItems,
    setLoading,
    schedulePickup,
    createShipping,
    setError,
    setLocations,
    setFlowToStart,
    setOpenScheduling,
    setPickupAvailability,
    setUserLocalTime,
    setSchedulingMainItem,
    setSchedulingPrivateResidence,
    setFormattedAddress,
    setSchedulingStreet,
    setSchedulingApt,
    setSchedulingPhoneNumber,
    setDate,
    setTime,
    nextStep,
    prevStep,
    setStepsLength,
    setShippingMethod,
    closeScheduling,
    setTimeRange,
    setDropOffLocation,
} = schedulingSlice.actions;
