/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Middleware } from 'redux';
import { addSchedulingDetails, itemsSlice, ItemsState } from '../store/itemsSlice';
import { resetShippingState, schedulingSlice, SchedulingState } from 'src/store/schedulingSlice';
import { Item } from 'src/types/item.ts';
import { setRoute } from 'src/store/sharedSlice';

const itemToShippingMiddleware: Middleware = ({ getState, dispatch }) => (next) => (action: unknown) => {
    // @ts-ignore
    if (action.type === itemsSlice.actions.updateItem.type) {
        const state = getState() as { scheduling: SchedulingState; items: ItemsState };

        const needReset = schedulingSlice.selectors.getReset(state);

        if (!needReset) {
            return next(action);
        }

        const selectedItems = schedulingSlice.selectors.getSelectedItems(state);
        const allItems = itemsSlice.selectors.getItems(state);

        // @ts-ignore
        const updatedItem = selectedItems.find((itemId) => itemId === action.payload?.publicId);

        if (updatedItem) {
            console.log('Processing scheduling details for matched items');

            selectedItems.forEach((itemId) => {
                const targetItem = allItems.find(
                    (allItem: Item) => allItem.publicId === itemId,
                );

                if (targetItem) {
                    const existingSchedulingDetails = targetItem.schedulingDetails;

                    // @ts-ignore
                    const schedulingDetailsToApply = existingSchedulingDetails || action.payload?.schedulingDetails;

                    dispatch(addSchedulingDetails({
                        publicId: targetItem.publicId,
                        schedulingDetails: schedulingDetailsToApply,
                    }));
                }
            });
        }

        dispatch(setRoute('/my-items'));
        dispatch(resetShippingState());
    }

    return next(action);
};

export default itemToShippingMiddleware;
